
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
padding-left: 100px;
padding-right: 100px;
}
body{
font-family: 'Montserrat', sans-serif;
}
a {
color: #304aa6;
text-decoration: none;
}
/*==========================================*/
.header{
box-shadow:0 1px 5px rgba(0, 0, 0, 0.06);
width:100%;
position: relative;
padding: 15px 0px;
}
.logo{
width: 175px;
}
/*==========================================*/
/*==========================================*/
section {
padding: 60px 0;
}
.tx-12{
font-size: 12px;
}
.fw600{
font-weight: 600;
}
.text-uppercase{
text-transform: uppercase;
}
.btn-full{
width:100%;
}
.btn-lg {
padding: 0.9rem 1rem;
font-size: 16px;
border-radius: 0.3rem;
font-weight: 600;
}
.btn-primary {
color: #fff;
background-color: #e34f73;
border-color: #e34f73;
}
/*==========================================*/
.login-form{
padding: 25px;
}
.form-group {
width: 100%;
position:relative;
}
.form-group label {
display: block;
margin-bottom: 5px;
font-size:14px;
font-weight:500;
}
.form-group input {
width:100%;
background-color: #fff;
border: 1px solid #c0ccda;
border-radius: 0.25rem;
font-size: 14px;
height: 50px;
padding: 0.46875rem 0.825rem;
}
.form-group select {
width:100%;
background-color: #fff;
border: 1px solid #c0ccda;
border-radius: 0.25rem;
font-size: 14px;
height: 50px;
padding: 0.46875rem 0.825rem;
}
/*==========================================*/
.golicon{
background: #fff;
border-radius: 10px;
box-shadow: 0 3px 6px 0 rgb(45 45 51 / 8%);
padding: 10px;
width:100%;
height: 150px;
border: 1px solid #eee;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
text-align: center;
cursor: pointer;
}
.golicon img{
width: 50px;
height: 50px;
margin-bottom: 15px;
}
.golicon h6{
font-weight: 600;
font-size: 16px;
}
.golicon-second{
background: #fff;
border-radius: 10px;
box-shadow: 0 3px 6px 0 rgb(45 45 51 / 8%);
padding: 10px;
width:100%;
height: 100px;
border: 1px solid #eee;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
text-align: center;
}
.golicon-second img{
width: 50px;
height: 50px;
margin-bottom: 15px;
}
.golicon-second h6{
font-weight: 600;
font-size: 16px;
}
/*===========================================*/
.parentDisable{
position: fixed;
top: 0;
left: 0;
background: #666;
opacity: 0.8;
z-index: 998;
height: 100%;
width: 100%;
}

.overlay-box {
position:absolute;
top:50%;
left:50%;
transform:translate(-50%, -50%);
color: white; background: #666666; opacity: .8;
z-index: 1000;
}
/*===========================================*/
.profile-box{
align-items: center;
float: right;
}
.profile-name{
width:40px;
height: 40px;
background: #000;
color:#fff;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
}
/*==========================================*/
.alertpop{
padding: 12px 20px !important;
text-align: center;
font-size: 14px !important;
border-color: #e34f73 !important; 
}
/*==========================================*/
@media (max-width: 789px) {
.container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
padding-left: 15px;
padding-right: 15px;
}
section {
padding: 30px 0;
}
.logo {
width: 125px;
}
.golicon-second h6{
font-size: 14px;
}

}
/*----------Breadcrumb---------*/
/*---------------------------*/
.edu-breadcrumb-area {
background-color: #f5f9fa;
padding: 95px 0 85px;
position: relative;
z-index: 1;
overflow: hidden;
}
.edu-breadcrumb-area-small {
background-color: #f5f9fa;
padding: 20px 0px 15px;
position: relative;
z-index: 1;
overflow: hidden;
}
.edu-breadcrumb-area-small h1{
font-size:24px;
margin-bottom:5px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
.edu-breadcrumb-area {
padding: 70px 0 60px;
}
}

@media only screen and (max-width: 767px) {
.edu-breadcrumb-area {
padding: 10px 0 5px;
}
}

.edu-breadcrumb-area .breadcrumb-inner {
text-align: center;
}

.edu-breadcrumb-area .page-title .pre-title {
margin-bottom: 15px;
letter-spacing: 1px;
display: block;
}

.edu-breadcrumb-area .page-title .title {
margin-bottom: 12px;
}

@media only screen and (max-width: 767px) {
.edu-breadcrumb-area .page-title .title {
font-size: 30px;
}
}

@media only screen and (max-width: 575px) {
.edu-breadcrumb-area .page-title .title {
font-size: 26px;
}
}

.edu-breadcrumb-area .edu-breadcrumb {
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
-webkit-flex-wrap: wrap;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
font-family: var(--font-secondary);
}
.edu-breadcrumb-area-small .edu-breadcrumb{
justify-content: start;
}
.edu-breadcrumb-area .edu-breadcrumb li{
margin:0;
}
.edu-breadcrumb-area .edu-breadcrumb li.breadcrumb-item {
color: var(--color-heading);
font-size: 11px;
font-weight: var(--p-medium);
}

.edu-breadcrumb-area .edu-breadcrumb li.breadcrumb-item a {
color: var(--color-heading);
}

.edu-breadcrumb-area .edu-breadcrumb li.breadcrumb-item a:hover {
color: var(--color-primary);
}

.edu-breadcrumb-area .edu-breadcrumb li.breadcrumb-item.active {
font-weight: var(--p-semi-bold);
}

.edu-breadcrumb-area .edu-breadcrumb li.separator  {
font-size: 16px;
font-weight: var(--p-bold);
margin: 0 10px;
color: var(--color-heading);
position: relative;
display: inline-block;
top: 2px;
}

.edu-breadcrumb-area .course-meta {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
margin-right: -20px;
margin-left: -20px;
}

@media only screen and (max-width: 767px) {
.edu-breadcrumb-area .course-meta {
display: block;
margin-top: 20px;
}
}

.edu-breadcrumb-area .course-meta li {
margin-right: 20px;
margin-left: 20px;
font-weight: 500;
color: var(--color-heading);
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
position: relative;
}

.edu-breadcrumb-area .course-meta li:after {
content: "";
height: 19px;
width: 1px;
background-color: #dbdbdb;
position: absolute;
top: 4px;
right: -20px;
}

@media only screen and (max-width: 767px) {
.edu-breadcrumb-area .course-meta li:after {
display: none;
}
}

.edu-breadcrumb-area .course-meta li:last-child:after {
display: none;
}

.edu-breadcrumb-area .course-meta li i {
padding-right: 14px;
font-size: 24px;
color: var(--color-primary);
}

.edu-breadcrumb-area .course-meta .course-rating .rating {
margin-right: 8px;
}

.edu-breadcrumb-area .course-meta .course-rating .rating i {
font-size: 15px;
color: #f8b81f;
padding: 0;
}

@media only screen and (max-width: 991px) {
.edu-breadcrumb-area .shape-group {
display: none;
}
}

.edu-breadcrumb-area .shape-group li {
position: absolute;
z-index: -1;
}

.edu-breadcrumb-area .shape-group li.shape-1 {
top: -315px;
left: -60px;
}

.edu-breadcrumb-area .shape-group li.shape-2 {
top: 60px;
left: 11%;
}

.edu-breadcrumb-area .shape-group li.shape-3 {
bottom: 120px;
right: 13%;
z-index: 1;
}

.edu-breadcrumb-area .shape-group li.shape-4 {
top: 30px;
right: -180px;
}

.edu-breadcrumb-area .shape-group li.shape-5 {
top: -70px;
right: -88px;
}

.edu-breadcrumb-area .shape-group li.shape-1, .edu-breadcrumb-area .shape-group li.shape-4 {
height: 470px;
width: 470px;
border: 1px solid var(--color-border);
border-radius: 50%;
}

.breadcrumb-style-2 {
position: relative;
z-index: 1;
padding: 190px 0 180px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
.breadcrumb-style-2 {
padding: 150px 0 140px;
}
}

@media only screen and (max-width: 767px) {
.breadcrumb-style-2 {
padding: 100px 0 90px;
}
}

@media only screen and (max-width: 575px) {
.breadcrumb-style-2 {
padding: 80px 0 70px;
}
}

.breadcrumb-style-2:before {
content: "";
height: 100%;
width: 100%;
background-color: rgba(0, 0, 0, 0.6);
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
z-index: -1;
}

.breadcrumb-style-2 .page-title {
width: 70%;
margin: 0 auto;
}

@media only screen and (max-width: 767px) {
.breadcrumb-style-2 .page-title {
width: 100%;
}
}

.breadcrumb-style-2 .page-title .title {
color: var(--color-white);
}

.breadcrumb-style-2 .edu-breadcrumb li.breadcrumb-item {
color: var(--color-white);
}

.breadcrumb-style-2 .edu-breadcrumb li.breadcrumb-item a {
color: var(--color-white);
}

.breadcrumb-style-2 .edu-breadcrumb li.separator i {
color: var(--color-white);
}

.breadcrumb-style-3 {
padding: 5px 0 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
.breadcrumb-style-3 {
padding: 5px 0 70px;
}
}

@media only screen and (max-width: 767px) {
.breadcrumb-style-3 {
padding: 5px 0 70px;
}
}

.breadcrumb-style-3 .breadcrumb-inner {
text-align: left;
}

.breadcrumb-style-3 .breadcrumb-inner .page-title {
width: 55%;
}

@media only screen and (max-width: 991px) {
.breadcrumb-style-3 .breadcrumb-inner .page-title {
width: 100%;
}
}

.breadcrumb-style-3 .breadcrumb-inner .page-title .title {
font-size: 36px;
margin-bottom: 4px;
line-height: 1.4;
}

@media only screen and (max-width: 767px) {
.breadcrumb-style-3 .breadcrumb-inner .page-title .title {
font-size: 30px;
}
}

@media only screen and (max-width: 575px) {
.breadcrumb-style-3 .breadcrumb-inner .page-title .title {
font-size: 26px;
}
}

.breadcrumb-style-3 .breadcrumb-inner .edu-breadcrumb {
-webkit-box-pack: start;
-webkit-justify-content: flex-start;
-ms-flex-pack: start;
justify-content: flex-start;
margin-bottom: 55px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
.breadcrumb-style-3 .breadcrumb-inner .edu-breadcrumb {
margin-bottom: 40px;
}
}

@media only screen and (max-width: 767px) {
.breadcrumb-style-3 .breadcrumb-inner .edu-breadcrumb {
margin-bottom: 40px;
}
}

.breadcrumb-style-3 .breadcrumb-inner .course-meta {
-webkit-box-pack: start;
-webkit-justify-content: flex-start;
-ms-flex-pack: start;
justify-content: flex-start;
}

.breadcrumb-style-3 .shape-group li.shape-2 {
left: 7%;
}

.breadcrumb-style-3 .shape-group li.shape-2 img {
width: 160px;
}

.breadcrumb-style-4 {
padding: 110px 0 105px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
.breadcrumb-style-4 {
padding: 80px 0;
}
}

@media only screen and (max-width: 767px) {
.breadcrumb-style-4 {
padding: 60px 0;
}
}

.breadcrumb-style-4 .page-title .title {
width: 75%;
margin: 0 auto 5px;
}

.breadcrumb-style-4 .course-meta li {
font-weight: 400;
color: var(--color-body);
}

@media only screen and (max-width: 767px) {
.breadcrumb-style-4 .course-meta li {
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
}
}

.breadcrumb-style-4 .course-meta li i {
font-size: 18px;
}

.breadcrumb-style-5 {
padding: 0;
padding-top: 18px;
background: -webkit-linear-gradient(right, #f5f1eb 0%, rgba(245, 241, 235, 0.3) 100%);
background: linear-gradient(-90deg, #f5f1eb 0%, rgba(245, 241, 235, 0.3) 100%);
}

.breadcrumb-style-5 .edu-breadcrumb {
-webkit-box-pack: start;
-webkit-justify-content: flex-start;
-ms-flex-pack: start;
justify-content: flex-start;
margin: -10px 0;
}
.breadcrumb-inner ul {
list-style-type: none;
}

/*=============================================*/
.home-tabs{
width:100%;
position: relative;
}
.home-tabs .nav-tabs .nav-link{
border: 0px;
border-bottom: 2px solid transparent;
color:#000;
}
.home-tabs .nav-tabs .nav-link.active{
border-bottom: 2px solid #e34f73;
}
.home-tabs .tab-pane{
    padding: 30px 0px;
}
.htbox{
    border: 1px solid #eee;
    padding: 25px;
    width:100%;
    position: relative;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.htbox .htbox-icon{
width:75px
}
.htbox .htbox-icon img{
    width:75px;
}
.htbox .htbox-content{
    flex:1;
    padding-left: 25px;
}
.htbox .htbox-content h6{
    font-weight: 600;
}
.htbox .htbox-content p{
    font-size: 14px;
}
/*=============================================*/


.bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb),var(--bs-bg-opacity))!important;
    box-shadow: 10 10 10;
    box-shadow: 0px 0px 6px #9e999a;
    margin-top: 50px;
    border-radius: 10px; 
    padding: 10px 20px 30px;
}

  .bg-white .table>:not(caption)>*>* {
    padding: 0px 40px 0px 0px;
    border-bottom-width: 0px !important;
}
  
.text-color{
  color: #949494;
}
  .bg-white .font-color td{
  color: #949494;
}
.bg-white .form-fill-btn{
  position: absolute;
    bottom: -6%;
    left: 25%;
}

